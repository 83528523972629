var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "btn-table-whatsapp",
      attrs: {
        target: "_blank",
        href: "https://wa.me/972" + this.data.substr(1),
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("img", {
        attrs: { src: require("@/assets/img/WhatsApp.svg"), alt: "" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }